import { Injectable, OnDestroy } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subscription } from 'rxjs';
import { SimpleTour } from 'src/app/helper/simple-tour';
import { PageTour } from 'src/app/model/page-tour.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PageTourService implements OnDestroy {

  tour = new ReplaySubject<PageTour | null>(1);
  private simpleTour?: SimpleTour;
  private subscription?: Subscription;
  private _tourId!: string;

  constructor(private http: HttpClient) {}

  setTour(tourKey: string, subKey?: string): void {
    if (this._tourId === tourKey + '#' + subKey) return;
    this._tourId = tourKey + '#' + subKey;

    if (tourKey) {
      const params: { [key: string]: string } = subKey ? {subKey: subKey} : {};
      this.http.get<PageTour | null>(environment.apiUrl + 'tour/' + tourKey, {params: params}).subscribe(tour => {
        if (!tour) return;

        this.tour.next(tour.steps.length ? tour : null);
        //check and start tour if push
        if (!tour.viewedFlag && tour.pushFlag) {
          this.showTour(tour);
        }
      });
      return;
    }
    this.tour.next(null);
  }

  showTour(pageTour: PageTour | null | undefined): void {
    if (!pageTour || pageTour?.pageTourStatCd !== 'A') {
      return;
    }
    setTimeout(() => {
      this.simpleTour = new SimpleTour({}, pageTour);
      this.simpleTour.tour?.start();
    });
  }

  ngOnDestroy(): void {
    this.simpleTour?.tour?.cancel();
    this.subscription?.unsubscribe();
  }

  setupModalTour(modal: NgbModalRef, pageRefCd: string, modalRefCd: string): void {
    modal.shown.subscribe(() => {
      this.setTour(pageRefCd, modalRefCd);
      this.tour.subscribe(tour => {
        modal.componentInstance.pageTour = tour;
      });
    });
    modal.closed.subscribe(() => {
      this.setTour(pageRefCd);
    });
  }

}
